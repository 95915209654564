// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"
import "./channels"

require('../../vendor/assets/admin-lte')

/*** jquery validation */
// yarn add jquery-validation
import "jquery-validation"
import "jquery-validation/dist/additional-methods"

/* Font awesome */
// yarn add @fortawesome/fontawesome-free
import '@fortawesome/fontawesome-free/js/all'

// yarn add toastr
import toastr from 'toastr'
window.toastr = toastr;

/* Sweetalert2 */
// yarn add sweetalert2
import Swal from "sweetalert2"
window.Swal = Swal;


/**** COMMON JAVASCRIPTS */
import "../../engines/app_modules/javascript/src/jquery"
import "../../engines/app_modules/javascript/src/fetch_with_timeout"
import "../../engines/app_modules/javascript/src/helpers"
import "../../engines/app_modules/javascript/src/languages"
import "../../engines/app_modules/javascript/src/variables"
import "../../engines/app_modules/javascript/src/engines_browser"

/**** ENGINES */
import '../../engines/lihtlogin/javascript/src/lihtlogin'
import '../../engines/lihtconfigs/javascript/src/lihtconfigs'
import '../../engines/lihtusers/javascript/src/lihtusers'
import '../../engines/customers/javascript/src/customers'
import '../../engines/transrates/javascript/src/transrates'
