//customers.js

import { 
    lihtlogin_langs_recover_password,
    lihtusers_langs_end_of_load,
    lihtusers_menu_labels,
    global_jquery_validate_langs,
    lihtusers_avatar_data,
    lihtusers_delete_user_alert_data
} from '../../../app_modules/javascript/src/languages';

import Dropzone from "dropzone";

Dropzone.autoDiscover = false;

$(function() {

    // Form Validation
    $.extend($.validator.messages, global_jquery_validate_langs[ global_values.locale ] );

    // Init pages loading all customers
    if ( window.location.pathname.includes('/customers/')  ) {
        fetch_actions_action( global_values, customers_fields_vals, global_urls.customers, 'load_customers_list', true )
        .then( data => {
            customers_load_customers_list_ui( data );
        })
        .catch( err => {
            console.log( err );
        });
    }

    $("#customers-create-customer-btn").on( 'click', ( e ) => {
        e.preventDefault();
        customers_create_new_customer();
    });

    if ($(".dropzone").length) {

        $(".dropzone").on( 'dragover', () => {
            console.log("MOUSEOVER");
            $(".dropzone").css( 'background-color', 'rgb(13, 110, 253)' );
        });
        $(".dropzone").on( 'dragleave', () => {
            console.log("MOUSEOUT");
            $(".dropzone").css( 'background-color', 'rgba(65, 84, 113, 0.6)' );
        });

        const auth_token = localStorage.getItem('auth_token');
        let uploader = new Dropzone(".dropzone",{
            url: "/es/customers/api/v1/upload_xlsx_file",
            dictDefaultMessage: 'Arrastre aqui una nueva tarifa en fichero de excel con los datos',
            dictRemoveFile: 'Eliminar fichero',
            paramName : "uploadedFiles",
            uploadMultiple :false,
            acceptedFiles : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            addRemoveLinks: true,
            forceFallback: false,
            maxFilesize:5000,
            parallelUploads: 1,
            //params: { "authenticity_token": $("#authenticity_token").val() },
            init: function() {
                this.on("sending", function(file, xhr, formData){
                    formData.append("authenticity_token", global_values.authenticity_token);
                });
            },
            //headers: { "Authorization":'Bearer' + auth_token }
            headers: { Authorization: `bearer ${auth_token}` }
        });//end drop zone

        uploader.on("drop", function(file,response) {
            console.log('response_drop', response);
        });

        uploader.on("success", function( file , response ) {
            $(".dropzone").css( 'background-color', 'rgba(65, 84, 113, 0.6)' );
            console.log('response_success', response);

            if ( response.status == 200 ) {
                const verification = response.verification;
                let html = ``;

                html += `<div class="row">`;
                html +=     `<div class="col-6">`;
                html +=         `<h3>Datos de verificación</h3>`;
                html +=         `<p>Numero de linea del xlsx: ${ verification.line }<br>`;
                html +=         `Provincia origen: ${ verification.origin }<br>`;
                html +=         `Provincia destino: ${ verification.delivery }<br>`;
                html +=         `Precio coche: ${ verification.car.toFixed(2) } €<br>`;
                html +=         `Precio monovolumen: ${ verification.minivan.toFixed(2) } €<br>`;
                html +=         `Precio furgoneta: ${ verification.van.toFixed(2) } €<br>`;
                html +=         `Tiempo tránsito: ${ verification.sla } días<br>`;
                html +=         `Precio conductor: ${ verification.driven } €</p>`;
                html +=     `</div>`;
                html +=     `<div class="col-6">`;
                html +=         `<h3>Usuario: ${ response.customer.name }</h3>`;
                html +=         `<button id="file-success" data-filename="${ response.filename }" data-customer_id="${ response.customer.id }" class="btn btn-success btn-block btn-lg"><i class="fas fa-check-circle"></i> Correcto: Procesar fichero</button>`;
                html +=         `<button id="file-error" class="btn btn-danger btn-block btn-lg"><i class="fas fa-times-circle"></i> Error: Volver a subir fichero</button>`;
                html +=     `</div>`;
                html += `</div>`;

                $("#dropzone-verification").html( html );

                hide_show( '#dropzone-wrapper', '#dropzone-verification', 200 );

            } else {
                toast_msg( response.error_msg, response.error_title, 'error', 'down' );
            }

        });

        uploader.on("complete", function(file, response){
            console.log('response_complete', response);
            this.removeAllFiles(true);
        });

    }

});

$( document ).on( 'click', "#btn-new-password", function( e ) {
    e.preventDefault();
    console.log( 'New Password' );
    const randomstring = Math.random().toString(36).slice(-8);
    $( '#customer-password' ).val( randomstring );
});

$( document ).on( 'click', "#file-error", function() {
    console.log( 'File Error' );
    hide_show( '#dropzone-verification', '#dropzone-wrapper', 200 );
});
    
$( document ).on( 'click', '#file-success', function() {
    const filename = $(this).data( 'filename' );
    const customer_id = $(this).data( 'customer_id' );
    console.log( 'File Success', filename, customer_id );

    $("#channel-cable").show();
    $("#channel-cable-h1").text( 'Procesando Tarifas' );
    $("#channel-cable-p").text( 'Incorporando las tarifas a la base de datos....' );

    process_filename_api( filename, customer_id );
});

$( document ).on( 'click', ".btn-customer-update", function() {
    const customer_id = $( this ).data( 'id' );
    console.log( 'Update Customer', customer_id );
    
    const customer_name = $( '#customer-name-'+customer_id ).val();
    const customer_email = $( '#customer-email-'+customer_id ).val();
    const customer_password = $( '#customer-password-'+customer_id ).val();

    customers_update_customer( customer_id, customer_name, customer_email, customer_password );

});

$( document ).on( 'click', ".btn-customer-destroy", function() {
    const customer_id = $( this ).data( 'id' );
    const customer_name = $( '#customer-name-'+customer_id ).val();
    console.log( 'Destroy Customer', customer_id );
    
    Swal.fire({
        title: '¿Está seguro?',
        html: "Va a eliminar a " + customer_name + " y sus tarifas asociadas.<br>Este paso no se puede deshacer<br>Este proceso puede durar unos segundos.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar'
    }).then((result) => {
        if ( result.isConfirmed ) {
            customers_destroy_customer( customer_id );
        }
    })
});



window.customers_load_customers_list_ui = ( data ) => {
    console.log(data);
    spin_out();

    $("#start-load").val( data.start_load );

    let customers = data.customers;

    let html = '';
    for ( let i=0; i<customers.length; i++ ) {
        html += compose_html_tr_table_customer( customers[i] );
    }

    customers_elements.customers_table_tbody.append(html);

    if ( !data.is_finished && $("#list-customer-layer").is(":visible") ) {
        toast_msg( 'CARGANDO...', 'Cargados ' +  data.start_load + ' de ' + data.customers_total + ' clientes', 'success', 'down' );
        lihtusers_fields.is_finished.val( '' );
    } else {
        toast_msg( 'TODOS CARGADOS', 'Cargados ' +  data.customers_total + ' de ' + data.customers_total + ' clientes', 'info', 'down' );
        customers_fields.is_finished.val( '1' );
    }

}

window.compose_html_tr_table_customer = ( customer ) => {

    let html = '';
    html += '<tr id="customer-'+customer.id+'">';

    html +=     '<td><button type="button" class="btn btn-danger btn-circle btn-lg btn-customer-destroy" data-id="'+customer.id+'"><i class="fas fa-user-times"></i></button></td>';
    html +=     '<td><button type="button" class="btn btn-warning btn-circle btn-lg btn-customer-update" data-id="'+customer.id+'"><i class="fas fa-sync"></i></button></td>';

    html +=     '<td>' + customer.id + '</td>';

    html +=     '<td>';
    html +=         '<div class="form-group">';
    html +=             '<input type="text" class="form-control api-user-field" name="customer_name" id="customer-name-'+customer.id+'" data-id="'+customer.id+'" placeholder="Nombre cliente" maxlength="255" value="'+customer.name+'">';
    html +=         '</div>';
    html +=     '</td>';

    html +=     '<td>';
    html +=         '<div class="form-group">';
    html +=             '<input type="text" class="form-control api-user-field" name="customer_email" id="customer-email-'+customer.id+'" data-id="'+customer.id+'" placeholder="Email cliente" maxlength="255" value="'+customer.email+'">';
    html +=         '</div>';
    html +=     '</td>';

    html +=     '<td>';
    html +=         '<div class="form-group">';
    html +=             '<input type="text" class="form-control api-user-field" name="customer_password" id="customer-password-'+customer.id+'" data-id="'+customer.id+'" placeholder="Contraseña" maxlength="255" value="'+customer.password+'">';
    html +=         '</div>';
    html +=     '</td>';

    html += '</tr>';

    return html;
}

window.customers_create_new_customer = () => {

    const customer_name = $( "#customer-name" ).val();
    const customer_email = $( "#customer-email" ).val();
    const customer_password = $( "#customer-password" ).val();

    $('#customers-new-customer-form').validate({
        rules: {
            name: {
                required: true
            },
            password: {
                required: true
            },
            email: {
                required: true,
                email: true,
                remote: '/lihtusers/api/v1/check_email'
            }
        },
        messages: {
            // email: {
            //     required: "Please enter a email address",
            //     email: "Please enter a vaild email address"
            // },
            // password: {
            //     required: "Please provide a password",
            //     minlength: "Your password must be at least 5 characters long"
            // },
        },
        errorElement: 'span',
        errorPlacement: function (error, element) {
            error.addClass('invalid-feedback');
            element.closest('.form-group').append(error);
            console.log(error);
        },
        highlight: function (element, errorClass, validClass) {
            $(element).addClass('is-invalid');
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).removeClass('is-invalid');
        }
    });

    if ( $('#customers-new-customer-form').valid() ) {

        console.log( 'Form Valido' );

        customers_fields_vals = {  };
        customers_fields_vals[ "customer_name" ] = customer_name;
        customers_fields_vals[ "customer_email" ] = customer_email;
        customers_fields_vals[ "customer_password" ] = customer_password;

        /// Send fetch
        fetch_actions_action_with_file( global_values, customers_fields_vals, global_urls.customers, 'create_new_customer' )
        .then( data => {
            spin_out();

            console.log( data );

            if ( data.status === 200 ) {

                const customer = data.customer;

                let html = '';
                html += '<tr id="customer-'+customer.id+'">';
                html +=     '<td><button type="button" class="btn btn-danger btn-circle btn-lg btn-customer-destroy" data-id="'+customer.id+'"><i class="fas fa-user-times"></i></button></td>';
                html +=     '<td><button type="button" class="btn btn-warning btn-circle btn-lg btn-customer-update" data-id="'+customer.id+'"><i class="fas fa-sync"></i></button></td>';

                html +=     '<td>' + customer.id + '</td>';
                
                html +=     '<td>';
                html +=         '<div class="form-group">';
                html +=             '<input type="text" class="form-control api-user-field" name="customer_name" id="customer-name-'+customer.id+'" data-id="'+customer.id+'" placeholder="Nombre cliente" maxlength="255" value="'+customer.name+'">';
                html +=         '</div>';
                html +=     '</td>';

                html +=     '<td>';
                html +=         '<div class="form-group">';
                html +=             '<input type="text" class="form-control api-user-field" name="customer_email" id="customer-email-'+customer.id+'" data-id="'+customer.id+'" placeholder="Email cliente" maxlength="255" value="'+customer.email+'">';
                html +=         '</div>';
                html +=     '</td>';

                html +=     '<td>';
                html +=         '<div class="form-group">';
                html +=             '<input type="text" class="form-control api-user-field" name="customer_password" id="customer-password-'+customer.id+'" data-id="'+customer.id+'" placeholder="Contraseña" maxlength="255" value="'+data.password+'">';
                html +=         '</div>';
                html +=     '</td>';

                html += '</tr>';

                customers_elements.customers_table_tbody.append(html);

                toast_msg( 'El usuario ' +  customer_name.toUpperCase() + ' con email ' + customer_email.toLowerCase() + ' se ha creado correctamente', 'CREANDO...', 'success', 'down' );
            } else {
                toast_msg( data.error_msg, 'Error', 'error', 'down' );
            }

            
        })
        .catch( err => {
            spin_out();
            console.log( err );
            toast_msg( err, 'Error', 'error', 'down' );
        });
            
    }

}

window.customers_update_customer = ( customer_id, customer_name, customer_email, customer_password ) => {

    if ( customer_name.length !== 0 && customer_password.length !== 0 && validate_email( customer_email ) ) {

        customers_fields_vals = {  };
        customers_fields_vals[ "customer_id" ] = customer_id;
        customers_fields_vals[ "customer_name" ] = customer_name;
        customers_fields_vals[ "customer_email" ] = customer_email;
        customers_fields_vals[ "customer_password" ] = customer_password;

        /// Send fetch
        fetch_actions_action( global_values, customers_fields_vals, global_urls.customers, 'update_customer', true )
        .then( data => {
            spin_out();
            console.log( 'update_customer', data );
            toast_msg( 'El usuario se ha actualizado correctamente', 'ACTUALIZADO...', 'success', 'down' );
        })
        .catch( err => {
            spin_out();
            console.log( err );
            toast_msg( err, 'Error', 'error', 'down' );
        });


    } else {
        toast_msg( 'Alguno de los campos de este usuario es incorrecto. Deben estar todos rellenos y el email debe tener formato de email', 'Error', 'error', 'down' );
    }
}

window.customers_destroy_customer = ( customer_id ) => {

    customers_fields_vals = {  };
    customers_fields_vals[ "customer_id" ] = customer_id;

    /// Send fetch
    fetch_actions_action( global_values, customers_fields_vals, global_urls.customers, 'destroy_customer', true )
    .then( data => {
        spin_out();
        console.log( 'destroy_customer', data );
        if ( data.status === 200 ) {
            $( '#customer-'+customer_id ).remove();
            toast_msg( 'El usuario se ha eliminado correctamente', 'ELIMINADO...', 'success', 'down' );
        } else {
            toast_msg( data.error_msg, 'ERROR', 'error', 'down' );
        }
    })
    .catch( err => {
        spin_out();
        console.log( err );
        toast_msg( err, 'Error', 'error', 'down' );
    });
}

window.process_filename_api = ( filename, customer_id ) => {

    customers_fields_vals = {  };
    customers_fields_vals[ "filename" ] = filename;
    customers_fields_vals[ "customer_id" ] = customer_id;

    /// Send fetch
    fetch_actions_action( global_values, customers_fields_vals, global_urls.customers, 'process_filename_api', false )
    .then( data => {
        console.log( 'process_filename_api', data );
        $("#channel-cable").hide();
        hide_show( '#dropzone-verification', '#dropzone-wrapper', 200 );
        toast_msg( 'La tarifas y los servicios extra han sido procesados correctamente', 'PROCESADOS...', 'success', 'down' );
    })
    .catch( err => {
        console.log( err );
    });

}

window.validate_email = ( email ) => {
    if ( /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test( email ) ) {
        return true;
    } else {
        return false;
    }
}