import consumer from "./consumer"

consumer.subscriptions.create("TarifasChannel", {
    connected() {
        // Called when the subscription is ready for use on the server
        console.log("Connected to TarifasChannel");
    },

    disconnected() {
        // Called when the subscription has been terminated by the server
        console.log("DISConnected to TarifasChannel");
    },

    received( data ) {
        // Called when there's incoming data on the websocket for this channel
        console.log( data );


        $("#channel-cable-h1").text( data.title );
        $("#channel-cable-p").text( data.text );
        $("#channel-cable-progress-bar").css( 'width', data.percent+'%' );
    }
});
