// helpers.js
$(function() {

    // reload on scroll
    $(window).on( 'scroll', function() {
        console.log( 'Se ejecuta window on scroll' );

        if ( window.location.pathname.includes('/lihtusers/') ) {
            if ( lihtusers_elements.list_user_layer.is(":visible") && !lihtusers_fields.is_finished.val() ) {
                if($(window).scrollTop() == $(document).height() - $(window).height()) {
                    global_values["start_load"] = $("#start-load").val();
                    fetch_actions_action( global_values, lihtusers_fields_vals, global_urls.users, 'load_users_list', true )
                    .then( data => {
                        lihtusers_load_users_list_ui( data );
                    })
                    .catch( err => {
                        console.log( err );
                    });
                }
            }
        } else if ( window.location.pathname.includes('/drivers/') ) {
            if ( drivers_elements.list_driver_layer.is(":visible") && !drivers_fields.is_finished.val() ) {
                if($(window).scrollTop() == $(document).height() - $(window).height()) {
                    global_values["start_load"] = $("#start-load").val();
                    fetch_actions_action( global_values, drivers_fields_vals, global_urls.drivers, 'load_drivers_list', true )
                    .then( data => {
                        drivers_load_drivers_list_ui( data );
                    })
                    .catch( err => {
                        console.log( err );
                    });
                }
            }
        }

    });


    // Commons search for all engines
    $("#header-search").on( 'keyup', (event) => {
        const search_length = $("#header-search").val().length;
        if ( search_length > 3 ) {
            $("#start-load").val( '0' );
            global_values[ "start_load" ] = $("#start-load").val();
            global_values[ "header_search" ] = $("#header-search").val();

            if ( window.location.pathname.includes('/lihtusers/') ) {
                fetch_actions_action( global_values, lihtusers_fields_vals, global_urls.users, 'load_users_list', true )
                .then( data => {
                    lihtusers_elements.users_table_tbody.empty();
                    lihtusers_load_users_list_ui( data );
                })
                .catch( err => {
                    console.log( err );
                });
            } else if ( window.location.pathname.includes('/drivers/') ) {
                fetch_actions_action( global_values, drivers_fields_vals, global_urls.drivers, 'load_drivers_list', true )
                .then( data => {
                    drivers_elements.drivers_table_tbody.empty();
                    drivers_load_drivers_list_ui( data );
                })
                .catch( err => {
                    console.log( err );
                });
            }
        }
    });


    $("#exit-search").on( 'click', function() {
        $("#header-search").val( '' );
        delete global_values[ "header_search" ];
        global_values[ "start_load" ] = 0;
        $("#start-load").val( '0' );

        if ( window.location.pathname.includes('/lihtusers/') ) {
            fetch_actions_action( global_values, lihtusers_fields_vals, global_urls.users, 'load_users_list', true )
            .then( data => {
                lihtusers_elements.users_table_tbody.empty();
                lihtusers_load_users_list_ui( data );
            })
            .catch( err => {
                console.log( err );
            });

        } else if ( window.location.pathname.includes('/drivers/') ) {
            fetch_actions_action( global_values, drivers_fields_vals, global_urls.drivers, 'load_drivers_list', true )
            .then( data => {
                drivers_elements.drivers_table_tbody.empty();
                drivers_load_drivers_list_ui( data );
            })
            .catch( err => {
                console.log( err );
            });

        }
    });

});



window.hide_show = ( hide, show, time ) => {
    $( hide ).fadeOut( time );
    setTimeout(() => {
        $( show ).fadeIn( time );
    }, time );
}

/****************** SPINNER **********************/
window.spin_in = ( html ) => {
    if ( html !== '' ) {
        $("#spin-text").html( html );
    }
    $("#spin-background").show();
}

window.spin_out = () => {
    $("#spin-text").html( '<i class="fas fa-circle-notch fa-spin"></i>' );
    $("#spin-background").hide();
}
/****************** SPINNER /END **********************/

window.check_remember = () => {
    if ( localStorage.getItem('user_email') != null ) {
        $( '#email' ).val( localStorage.getItem( 'user_email' ) );
        $( '#remember' ).prop( 'checked', true );
    }
}

window.fetch_actions_action = ( global_values, custom_values, engine_url, url, spin ) => {
    if ( spin ) {
        spin_in();
    }

    let params_values = {};
    for ( var key in global_values ) {
        params_values[ key ] = global_values[ key ];
    }
    for ( var key in custom_values ) {
        params_values[ key ] = custom_values[ key ];
    }
    

    return fetch('/' + global_values.locale + engine_url + url,
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${ global_values.auth_token }`,
            },
            body: JSON.stringify( params_values ),
        },
        10000
    )
    .then((response) => {
        return response.json()
    })
    .then((data) => {
        return data;
    })
    .catch(( err ) => {
        spin_out();
        return err;
    })
}

window.fetch_actions_action_with_file = ( global_values, custom_values, engine_url, url ) => {
    let values = new FormData();
    for ( var key in global_values ) {
        values.append( key, global_values[ key ] );
    }
    for ( var key in custom_values ) {
        values.append( key, custom_values[ key ] );
    }

    spin_in();
    return fetch('/' + global_values.locale + engine_url + url,
        {
            method: "post",
            headers: {
                //"Content-Type": "application/json",
                Authorization: `bearer ${ global_values.auth_token }`,
            },
            body: values,
        },
        10000
    )
    .then((response) => {
        return response.json()
    })
    .then((data) => {
        return data;
    })
    .catch(( err ) => {
        spin_out();
        return err;
    })
}

/****** Helpers Toast */
// Types of toats => https://github.com/CodeSeven/toastr
window.toast_msg = (title, text, type, pos) => {
    let position;
    if( pos === 'up' ){
        position = "toast-top-right";
    } else {
        position = "toast-bottom-left";
    }
    toastr.options = {
        "newestOnTop": true,
        "positionClass": position,
        "preventDuplicates": false,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": "5000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    }
    toastr[type]( title, text );
}

/****** Helpers Sweetalert */
window.sweetalert_error = (text, subject, body) => {
    Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: text,
        footer: 'Si el problema persiste <a href="mailto:cs@liht.com?subject=' + subject + '&body=' + body + '"> <strong> PULSE AQUI</strong></a>'
    })
}

window.sweetalert_error_401 = () => {
    Swal.fire({
        icon: 'error',
        title: 'Login',
        text: 'Su sesión ha caducado o no esta correctamente logeado.',
        footer: '<a href="/"> <strong> PULSE AQUI</strong></a> para ir a la página de acceso'
    })
}

window.sweetalert_error_for_actions = (title, text, footer) => {
    Swal.fire({
        icon: 'error',
        title: title,
        text: text,
        footer: footer
    })
}

window.sweetalert_error_for_actions_html = (title, text, footer) => {
    Swal.fire({
        icon: 'error',
        title: title,
        html: text,
        footer: footer
    })
}


