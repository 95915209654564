// lihtlogin.js

import { 
    lihtlogin_langs_recover_password,
    lihtusers_langs_end_of_load,
    lihtusers_menu_labels,
    global_jquery_validate_langs,
    lihtusers_avatar_data,
    lihtusers_delete_user_alert_data
} from '../../../app_modules/javascript/src/languages';

$(function() {

    // Form Validation
    $.extend($.validator.messages, global_jquery_validate_langs[ global_values.locale ] );

    // Launch check remember on load
    check_remember();

    $( document ).on( 'click', "#btn-logout", (event) =>  {
        event.preventDefault();
        lihtlogin_logout_action();
    });

    $( document ).on( 'click', ".go-forgot", function( e ) {
        e.preventDefault();
        console.log( 'go-forgot' );
        hide_show( '.login-layer', '.forgot-layer', 200 );
    });

    $( document ).on( 'click', ".go-login", function( e ) {
        e.preventDefault();
        hide_show('.forgot-layer', '.login-layer', 200 );
    });

    $( document ).on( 'click', ".btn-login", function( e ) {
        e.preventDefault();

        let remember_btn = 0;
        if ( $('#remember' ).prop( 'checked' ) ) {
            remember_btn = 1;
        }
        lihtlogin_fields["email"] = lihtlogin_elements.email_field.val();
        lihtlogin_fields["password"] = lihtlogin_elements.password_field.val();
        lihtlogin_fields["remember"] = remember_btn

        fetch_actions_action( global_values, lihtlogin_fields, global_urls.login, 'login', true )
        .then( data => {
            liht_login_refresh_ui( data );
        })
        .catch( err => {
            console.log( err );
        });
    });


    $( document ).on( 'click', ".btn-forgot-password", function( e ) {
        e.preventDefault();

        lihtlogin_fields[ "email" ] = lihtlogin_elements.forgot_email.val();

        fetch_actions_action( global_values, lihtlogin_fields, global_urls.login, 'forgot_password', true )
        .then( data => {
            spin_out();
            if ( data.status === 200 ) {
                toast_msg( `Revise su email ${ data.email } donde tendrá un correo con el enlace para restaurar su contraseña`, 'CORREO ENVIADO', 'success', 'up' );
            } else {
                toast_msg( data.error_msg, data.error_title, 'error', 'up' );
            }
        })
        .catch( err => {
            console.log( err );
        });
    });

    $( document ).on( 'click', ".btn-restore-password", function( e ) {
        e.preventDefault();

        validate_restore_password_form();

        if ( $("#restore-password-form").valid() && lihtlogin_check_strong_password( locale, $("#password-restore").val() ) ) {
            /// Load form fields
            lihtusers_fields_vals[ "key" ] = $("#key").val()
            lihtusers_fields_vals[ "password" ] = $("#password-restore").val()
            lihtusers_fields_vals[ "email" ] = $("#forgot_password_user_email").val()

            /// Send fetch
            fetch_actions_action( global_values, lihtusers_fields_vals, global_urls.login, 'restore_password', true )
            .then( data => {
                liht_login_refresh_ui( data );
            })
            .catch( err => {
                console.log( err );
            });
        }

    });


    $( document ).on( 'click', ".btn-verify-code", function( e ) {
        e.preventDefault();
        check_code_user_email = $( "#check_code_user_email" ).val();
        check_code_verify_code = $( "#check_code_verify_code" ).val();
        check_code_previous_ip = $( "#check_code_previous_ip" ).val();

        lihtlogin_fields["user_email"] = check_code_user_email;
        lihtlogin_fields["check_code_verify_code"] = check_code_verify_code;
        lihtlogin_fields["check_code_previous_ip"] = check_code_previous_ip;

        fetch_actions_action( global_values, lihtlogin_fields, global_urls.login, 'verify_code', true )
        .then( data => {
            liht_login_refresh_ui( data );
        })
        .catch( err => {
            console.log( err );
        });

    });

});

window.liht_login_refresh_ui = ( data ) => {

    console.log(data);
    spin_out();

    if ( data.status == 401 ) {
        lihtlogin_elements.error_login_title.text( data.error_title );
        lihtlogin_elements.error_login_msg.text( data.error_msg );
        lihtlogin_elements.login_alert_wrapper.fadeIn( 500 );
        $(".login-alert-wrapper").fadeIn(500);            

        setTimeout(() => {
            lihtlogin_elements.login_alert_wrapper.fadeOut( 500 );
        }, 5000);

    } else if ( data.status == 201 ) {
        hide_show('.login-layer', '.code-layer', 200);
        lihtlogin_elements.verify_code_title.show();
        lihtlogin_elements.check_code_user_email.val( data.user.email );
        lihtlogin_elements.check_code_previous_ip.val( data.ip );

    } else if ( data.status == 202 ) {
        hide_show('.code-layer', '.login-layer', 200);
        lihtlogin_elements.verify_code_title.show();
        lihtlogin_elements.check_code_user_email.val( data.user.email );
        lihtlogin_elements.check_code_previous_ip.val( data.ip );
        toast_msg(data.error_msg, data.error_title, 'error', 'up');

    } else {
        localStorage.setItem('auth_token', data.auth_token);
        global_values[ 'auth_token' ] = data.auth_token;

        if ( lihtlogin_elements.remember.val() == "1" ){
            localStorage.setItem('user_email', data.user.email);
        } else {
            localStorage.removeItem('user_email');
        }

        const url = "/" + data.locale + data.user.app.url; // For redirect to init app
        lihtlogin_elements.login_redirect_form.html( redirect_form() );
        lihtlogin_elements.login_redirect_form.attr( 'action', url );
        lihtlogin_elements.login_redirect_form.trigger( 'submit' );

    }

}


window.lihtlogin_logout_action = () => {
    spin_in( '' );
    setTimeout(() => {
        spin_out();
        localStorage.removeItem('auth_token');
        window.location.href = '/';
    }, 1000);

}

window.validate_restore_password_form = () => {

    $('#restore-password-form').validate({
        rules: {
            key: {
                required: true,
                minlength: 128
            },
            password_restore: {
                required: true,
                minlength: 8
            },
            password_restore_confirm: {
                required: true,
                minlength: 8,
                equalTo: "#password-restore"
            }
        },
        messages: {
            // email: {
            //     required: "Please enter a email address",
            //     email: "Please enter a vaild email address"
            // },
            // password: {
            //     required: "Please provide a password",
            //     minlength: "Your password must be at least 5 characters long"
            // },
        },
        errorElement: 'span',
        errorPlacement: function (error, element) {
            error.addClass('invalid-feedback');
            element.closest('.input-group').append(error);
            console.log(error);
        },
        highlight: function (element, errorClass, validClass) {
            $(element).addClass('is-invalid');
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).removeClass('is-invalid');
        }
    });

}

window.lihtlogin_check_strong_password = (locale, password) => {
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
    if( password != "" ) {
        if (strongRegex.test(password)){
            $("#password-error").text('').hide();
            return true;
        } else {
            $("#password-error").text(lihtlogin_langs_recover_password[ global_values.locale ].regex.body).show();
            toast_msg( 'Su contraseña debe tener al menos 8 caracteres incluyendo una mayuscula una minuscula y un número', 'CONTRASEÑA DÉBIL', 'error', 'up' );
            return false;
        }
    } else {
        return true;
    }
}