// variables.js

/*** Global Values */
const global_values = {
    locale: $("#locale").val(),
    authenticity_token: $("#authenticity_token").val(),
    auth_token: localStorage.getItem( 'auth_token' ),
};
window.global_values = global_values;

const global_urls = {
    login: '/login/api/v1/',
    config: '/configs/api/v1/',
    users: '/lihtusers/api/v1/',
    customers: '/customers/api/v1/',
    transrates: '/transrates/api/v1/'
};
window.global_urls = global_urls;

/*** Global Values //END */



/**** Redirect Form */
const redirect_form = () => {
    let form = '' +
    //'<form action="/' + url + '" method="post" id="login-redirect-form">' + 
    '<input type="hidden" name="auth_token" value="' + global_values.auth_token + '">' + 
    '<input type="hidden" name="authenticity_token" value="' + global_values.authenticity_token + '">' + 
    '';
    //'</form>';
    return form;
}
window.redirect_form = redirect_form;
/**** Redirect Form //END */




/*** Sidebars */
const browsing = {
    login_redirect_form: $( "#login-redirect-form" ),
    lihtconfigs_sidebar_menu: $( "#lihtconfigs-sidebar-menu" ),
    lihtusers_sidebar_menu: $( "#lihtusers-sidebar-menu" ),
    customers_sidebar_menu: $( "#customers-sidebar-menu" )
}
window.browsing = browsing;

/*** Sidebars //END */





/*** Lihtlogin Values */
const lihtlogin_fields = {
    email: $("#email").val(),
    password: $("#password").val()
};
window.lihtlogin_fields = lihtlogin_fields;

const lihtlogin_elements = {
    email_field: $( "#email" ),
    password_field: $( "#password" ),
    forgot_email: $("#forgot-email"),
    error_login_title: $( "#error-login-title" ),
    error_login_msg: $( "#error-login-msg" ),
    login_alert_wrapper: $( "#login-alert-wrapper" ),
    login_alert_wrapper_forgot_password: $( "#login-alert-wrapper-forgot-password" ),
    login_alert_wrapper_verify_code: $( "#login-alert-wrapper-verify-code" ),
    verify_code_title: $( "#verify-code-title" ),
    user_email: $( "#user_email" ),
    check_code_user_email: $( "#check_code_user_email" ),
    check_code_previous_ip: $( "#check_code_previous_ip" ),
    login_redirect: $( "#login-redirect" ),
    login_redirect_form: $( "#login-redirect-form" ),
    remember: $( "#remember" )
}
window.lihtlogin_elements = lihtlogin_elements;

/*** Lihtlogin Values //END */





/*** Lihtconfig Values */
const lihtconfig_fields = {
    app_id: $( "#app_id" ).val(),
    app_name: $( "#app_name" ).val(),
    app_url: $( "#app_url" ).val(),
    group_id: $( "#group_id" ).val(),
    group_name: $( "#group_name" ).val()
}
window.lihtconfig_fields = lihtconfig_fields;

const lihtconfig_elements = {
    app_id_field: $( "#app_id" ),
    app_name_field: $( "#app_name" ),
    app_url_field: $( "#app_url" ),
    group_id_field: $( "#group_id" ),
    group_name_field: $( "#group_name" ),
    lihtconfigs_apps_list: $( "#lihtconfigs-apps-list" ),
    lihtconfigs_groups_list: $( "#lihtconfigs-groups-list" )
    //btn_create_modify_app: $( "#btn-create-modify-app" )
}
window.lihtconfig_elements = lihtconfig_elements;
/*** Lihtconfig Values //END */








/*** Lihtusers Values */
const lihtusers_fields = {
    name_surname: $("#name-surname"),
    email: $("#email"),
    password: $("#password"),
    password_confirm: $("#password_confirm"),
    app_id: $("#app_id"),
    inherit_id: $("#inherit_id"),
    is_actived: $("#is_actived"),
    newsletter: $("#newsletter"),
    user_avatar: $("#user-avatar"),
    edit_user_id: $("#edit_user_id"),
    previous_email: $("#previous_email"),
    is_finished: $("#is_finished")
}
window.lihtusers_fields = lihtusers_fields;

const lihtusers_fields_vals = {
    
}
window.lihtusers_fields_vals = lihtusers_fields_vals

const lihtusers_elements = {
    list_user_layer: $("#list-user-layer"),
    new_user_layer: $("#new-user-layer"),
    list_users: $("#lihtusers-list-users"),
    new_user: $("#lihtusers-new-user"),
    users_table: $("#lihtusers-users-table"),
    users_table_tbody: $("#lihtusers-users-table tbody"),

    new_user_form: $("#new-user-form"),
    edit_user_form: $("#edit-user-form"),
    btn_delete_image_user: $("#btn-delete-image-user"),
    btn_create_new_user: $("#btn-create-new-user"),
    btn_edit_user: $("#btn-edit-user"),
    upload_preview: $("#upload-preview"),
    file_info: $("#lihtusers-file-info"),
    edit_info_show: $(".lihtusers-edit-info-show")

}
window.lihtusers_elements = lihtusers_elements;

/*** Lihtusers Values //END */








/*** Customers Values */
const customers_fields = {
    only_actived: $("#only_actived"),
    only_broom: $("#only_broom"),
    is_finished: $("#is_finished")
}
window.customers_fields = customers_fields

const customers_fields_vals = {
    
}
window.customers_fields_vals = customers_fields_vals

const customers_elements = {
    list_customers_layer: $("#list-customers-layer"),
    customers_table: $("#customers-customers-table"),
    customers_table_tbody: $("#customers-customers-table tbody"),
}
window.customers_elements = customers_elements;


/*** Customers Values //END */

