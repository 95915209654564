//transrates.js

$(function() {

    $( document ).on( 'click', ".btn-refresh-customer", function() {
        browse_to_engine( '/es/transrates/transrates/index' )
    });
    
    $( document ).on( 'click', ".btn-logout-customer", function() {
        lihtlogin_logout_action();
    });

    ///***** Reset screen to clean screen ****/
    $( document ).on( 'click', ".btn-erase", function() {
        restore_transrates_form();
    });

    ///***** Search a car with num and color ****/
    $( document ).on( 'click', ".btn-calculate", function() {
        if ( check_transrates_form_before_submit() ) {
            calculate_rates();
        }
    });

    $( document ).on( 'click', ".update-with-extra-services", function() {
        if ( check_transrates_form_before_submit() ) {
            calculate_rates();
        }
    });
    
    $( document ).on( 'change', "#province_origin_id, #province_destination_id, #province_vehicle", function() {
        $(".transrates-result-wrapper").empty();
    });

});



window.restore_transrates_form = () => {
    $(".custom-select").prop("selectedIndex", 0);
    $('input[name="extra_services_codes"]').each(function() {
        this.checked = false;
    });
    $(".transrates-result-wrapper").text("");
}

window.check_transrates_form_before_submit = () => {
    let message = '';
    if ( $("#province_origin_id").val() == '' ) {
        message += '<p>Debe seleccionar una Provincia de Origen</p>';
    }

    if ( $("#province_destination_id").val() == '' ) {
        message += '<p>Debe seleccionar una Provincia de Destino</p>';        
    }

    if ( $("#province_vehicle").val() == '' ) {
        message += '<p>Debe seleccionar un Tipo de Vehículo</p>';
    }

    if ( message == '' ) {
        return true;
    } else {
        sweetalert_error_for_actions_html("Error en los campos", message, "")
        return false;
    }

}

window.calculate_rates = () => {
    let extra_services = $(".transrates-checkbox-wrapper input:checkbox:checked").map(function(){
        return $(this).val();
    }).get();

    // console.log( 'extra services', extra_services );

    let data_post = {
        province_origin_id: $("#province_origin_id").val(),
        province_destination_id: $("#province_destination_id").val(),
        province_vehicle: $("#province_vehicle").val(),
        extra_services: extra_services
    }

    /// Send fetch
    fetch_actions_action( global_values, data_post, global_urls.transrates, 'calculate_rates', true )
    .then( data => {
        spin_out();
        // console.log( 'calculate_rates', data );
        // toast_msg( 'La tarifas y los servicios extra han sido procesados correctamente', 'PROCESADOS...', 'success', 'down' );

        if( data.status == 200 ) {
            let html_str = '';
            html_str += '<div class="row">';
            html_str += '<h4>Precios transporte en camión:</h4>';
            html_str += '<div class="col-6">';
            html_str += '<h5>SIN IVA</h5>';
            html_str += '<p>' + data.data.rate_witout_vat + ' €</p>';
            html_str += '</div>';
            html_str += '<div class="col-6">';
            html_str += '<h5>CON IVA</h5>';
            html_str += '<p>' + data.data.rate_with_vat + ' €</p>';
            html_str += '</div>';
            html_str += '<div class="col-12">';
            html_str += '<h5>Tiempo de tránsito: <strong>' + data.data.sla + ' días</strong></h5>';
            html_str += '</div>';
            html_str += '</div>';

            html_str += '<div class="row">';
            html_str += '<h4 style="margin-top: 1rem;">Precios transporte con conductor:</h4>';
            html_str += '<div class="col-6">';
            html_str += '<h5>SIN IVA</h5>';
            html_str += '<p>' + data.data.driven_without_vat + ' €</p>';
            html_str += '</div>';
            html_str += '<div class="col-6">';
            html_str += '<h5>CON IVA</h5>';
          html_str += '<p>' + data.data.driven_with_vat + ' €</p>';
            html_str += '</div>';
            html_str += '<div class="col-12">';
            html_str += '<h5>Tiempo de tránsito: <strong>24/48 horas</strong></h5>';
            html_str += '</div>';
            html_str += '</div>';
            
            $(".transrates-result-wrapper").html( html_str );

        } else if ( data.status == 201 ) {
            spin_out();
            sweetalert_error_for_actions_html("Error", data.error, "")

        } else if ( data.status == 500 ) {
            spin_out();
            sweetalert_error('Error de ejecución', 'Error code: E014', data.error+'. Envíe este correo manteniendo el código de error que aparece en el asunto para que podamos revisarlo. Si lo desea puede añadir aqui algún detalle más que pueda ayudarnos a encontrar el problema.');
        } else if ( data.status == 401 ) {
            spin_out();
            sweetalert_error_401();
        }

    })
    .catch( err => {
        spin_out();
        console.log( err );
        sweetalert_error('Problema de conexión con el servidor', 'Error code: E009', err+'. Envíe este correo manteniendo el código de error que aparece en el asunto para que podamos revisarlo. Si lo desea puede añadir aqui algún detalle más que pueda ayudarnos a encontrar el problema.');
    });

}

// window.calculate_rates_ori = () => {

//     let extra_services = $(".transrates-checkbox-wrapper input:checkbox:checked").map(function(){
//         return $(this).val();
//     }).get();

//     let data_post = {
//         province_origin_id: $("#province_origin_id").val(),
//         province_destination_id: $("#province_destination_id").val(),
//         province_vehicle: $("#province_vehicle").val(),
//         extra_services: extra_services
//     }

//     spin_in();
//     const auth_token = localStorage.getItem('auth_token');
//     fetch("/es/transrates/rates/calculate_rates",
//         {
//             method: "post",
//             headers: {
//                 "Content-Type": "application/json",
//                 Authorization: `bearer ${auth_token}`,
//             },
//             body: JSON.stringify(data_post),
//         },
//         10000
//     )
//     .then((response) => { return response.json() })
//     .then((data) => {
//         console.log(data);

//         if( data.status == 200 ) {
//             spin_out();

//             //console.log(data)

//             let html_str = '';
//             html_str += '<div class="row">';
//             html_str += '<div class="col-6">';
//             html_str += '<h4>SIN IVA</h4>';
//             html_str += '<p>' + data.data.rate_witout_vat + ' €</p>';
//             html_str += '</div>';
//             html_str += '<div class="col-6">';
//             html_str += '<h4>CON IVA</h4>';
//             html_str += '<p>' + data.data.rate_with_vat + ' €</p>';
//             html_str += '</div>';
//             html_str += '<div class="col-12">';
//             html_str += '<h5>Tiempo de tránsito: <strong>' + data.data.sla + ' días</strong></h5>';
//             html_str += '</div>';
//             html_str += '</div>';
            
//             $(".transrates-result-wrapper").html(html_str);

//         } else if ( data.status == 201 ) {
//             spin_out();
//             sweetalert_error_for_actions("Error", data.error, "")

//         } else if ( data.status == 500 ) {
//             spin_out();
//             sweetalert_error('Error de ejecución', 'Error code: E014', data.error+'. Envíe este correo manteniendo el código de error que aparece en el asunto para que podamos revisarlo. Si lo desea puede añadir aqui algún detalle más que pueda ayudarnos a encontrar el problema.');
//         } else if ( data.status == 401 ) {
//             spin_out();
//             sweetalert_error_401();
//         }
        
//     })
//     .catch((error) => {
//         spin_out();
//         console.log(error);
//         sweetalert_error('Problema de conexión con el servidor', 'Error code: E009', error+'. Envíe este correo manteniendo el código de error que aparece en el asunto para que podamos revisarlo. Si lo desea puede añadir aqui algún detalle más que pueda ayudarnos a encontrar el problema.');
//     })

// }

